import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import 'twin.macro'
import Plans from './components/plans'

const PlanPage = (props) => {
	const title = (props.location.state && props.location.state.title) ? props.location.state.title : 'Plans'
	return <Layout {...props}>
		<SEO title={title}/>
		<Plans title={title} {...props}/>
	</Layout>
}

export default PlanPage